import {getIndexElement} from '../../scripts/tools';

declare var ScrollMagic: any;
declare var TimelineMax: any;
declare var Power1: any;

export default class AnimatedVacancies {
    private CLASS_BLOCK: string = 'js-animatedVacancies';
    private CLASS_ITEM_CONTAINER: string = 'js-animatedVacanciesContainer';

    private scrollMagicController: any;

    public init(): void {
        this.scrollMagicController = new ScrollMagic.Controller();

        this.animate();
    }

    private animate(): void {
        const timeline: any = new TimelineMax();
        const container: HTMLElement = document.querySelector(`.${this.CLASS_ITEM_CONTAINER}`);
        if (!container) {
            return;
        }
        const activeItem: HTMLElement = container.querySelector(`#tns1-item0`);
        const activeItemIndex: number = getIndexElement(container, activeItem);
        const items: Array<Element> = Array.from(container.children);

        items.forEach((item, index) => {
            if (index < activeItemIndex) {
                timeline.to(item, 0, {ease: Power1.easeOut, x: '0%', opacity: 1}, 0);
            } else {
                timeline.to(item, 0.8, {
                    ease: Power1.easeOut,
                    x: '0%',
                    opacity: 1,
                    delay: 0.2 + ((index / 100) * 2)
                }, '-=1');
            }
        });

        new ScrollMagic.Scene({
            triggerElement: `.${this.CLASS_BLOCK}`,
            reverse: false,
        })
            .setTween(timeline)
            // .addIndicators()
            .addTo(this.scrollMagicController);
    }
}
