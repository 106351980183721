import {TinySliderSettings} from '../../../node_modules/tiny-slider/src/tiny-slider';

interface SliderConfigObjects {
    [key: string]: TinySliderSettings;
}

export const sliderConfigs: SliderConfigObjects = {
    'js-sliderBrands': {
        controls: false,
        items: 1,
        loop: false,
        nav: false,
        autoWidth: true,
        gutter: 60,
        responsive: {
            1024: {
                gutter: 140,
            },
        },
    },
    'js-sliderDevice': {
        controls: false,
        items: 1,
        nav: false,
        autoplay: true,
        mouseDrag: false,
        touch: false,
        gutter: 0,
        loop: true,
    },
    'js-sliderVacancies': {
        items: 1,
        loop: true,
        nav: false,
        mouseDrag: false,
        speed: 700,
        autoHeight: true,
        responsive: {
            768: {
                items: 3,
            },
            600: {
                items: 2,
                autoHeight: false,
            },
        },
    },
};
