import Modal from '../modules/modal/Modal';
import {globalEventDelegate} from '../tools';
import SendRequest from './SendRequest';

export default class ModalLeaveRequest {
    private CLASS_HANDLER: string = 'js-modalLeaveRequestHandler';
    private CLASS_SEND_HANDLER: string = 'js-sendRequestHandlerModal';

    private ID_MODAL: string = 'js-modalLeaveRequest';

    private modal: Modal;
    private sendRequest: SendRequest;

    public init(): void {
        const modalHtml: HTMLElement = document.getElementById(this.ID_MODAL);
        if (!modalHtml) {
            return;
        }
        this.sendRequest = new SendRequest(this.CLASS_SEND_HANDLER, {
            success: () => {
                this.modal.close();
            },
        });
        this.sendRequest.init();
        this.modal = new Modal(modalHtml, {
            hasBackdrop: true,
        });
        this.modal.init();

        this.attachEvent();
    }

    private attachEvent(): void {
        globalEventDelegate('click', `.${this.CLASS_HANDLER}`, (element, event) => {
            event.preventDefault();
            this.modal.open();
        });
    }

}
