declare var TimelineMax: any;
declare var ScrollMagic: any;
declare var Power1: any;

export default class AnimatedThechnologyCube {
    private CLASS_MAIN: string = 'js-animatedThechnologyCubeMain';
    private CLASS_COVER: string = 'js-animatedThechnologyCubeCover';
    private CLASS_CONTENT: string = 'js-animatedThechnologyCubeContent';

    public init(): void {
        this.activate();
    }

    private activate(): void {
        const elements: Array<HTMLElement> = Array.from(
            document.getElementsByClassName(this.CLASS_MAIN),
        ) as Array<HTMLElement>;

        elements.forEach((element) => {
            const timeline: any = new TimelineMax();
            const cover: HTMLElement = element.querySelector(`.${this.CLASS_COVER}`);
            const content: HTMLElement = element.querySelector(`.${this.CLASS_CONTENT}`);
            timeline.to(cover, 0.6, {ease: Power1.easeOut, x: 0})
                    .to(cover, 1, {ease: Power1.easeOut, xPercent: 100})
                    .to(content, 1, {ease: Power1.easeOut, opacity: 1}, '-=1');

            const controller = new ScrollMagic.Controller();
            const scene = new ScrollMagic.Scene({
                triggerElement: element,
                offset: -element.clientHeight,
                reverse: false,
            })
                .setTween(timeline)
                // .addIndicators()
                .addTo(controller);
        });
    }
}
