// import {TimelineMax} from 'gsap';

declare var TimelineMax: any;
declare var ScrollMagic: any;
declare var Power1: any;

export default class AnimatedCardInterests {
    private CLASS_DEV: string = 'js-animCardInterestsDev';
    private CLASS_DEV_IMAGE: string = 'js-animCardInterestsDevImage';
    private CLASS_DEV_GEAR_TOP: string = 'js-animCardInterestsDevGearTop';
    private CLASS_DEV_GEAR_BOTTOM: string = 'js-animCardInterestsDevGearBottom';
    private CLASS_DEV_TITLE: string = 'js-animCardInterestsDevTitle';
    private CLASS_DEV_CODE: string = 'js-animCardInterestsDevCode';

    private CLASS_GOV: string = 'js-animCardInterestsGov';
    private CLASS_GOV_IMAGE: string = 'js-animCardInterestsGovImage';
    private CLASS_GOV_GEAR_TOP: string = 'js-animCardInterestsGovGearTop';
    private CLASS_GOV_GEAR_BOTTOM: string = 'js-animCardInterestsGovGearBottom';
    private CLASS_GOV_TITLE: string = 'js-animCardInterestsGovTitle';

    private dev: HTMLElement;
    private gov: HTMLElement;

    private timeline: any;

    public init(): AnimatedCardInterests {
        this.dev = document.querySelector(`.${this.CLASS_DEV}`);
        this.gov = document.querySelector(`.${this.CLASS_GOV}`);
        this.timeline = new TimelineMax({
            repeat: -1,
        });

        return this;
    }

    public animate(): AnimatedCardInterests {
        this.timeline
            .to(`.${this.CLASS_DEV_GEAR_TOP}`, 1, {ease: Power1.easeOut, x: '-40%', y: '-40%', opacity: 1}, 0)
            .to(`.${this.CLASS_DEV_GEAR_BOTTOM}`, 1, {ease: Power1.easeOut, x: '50%', y: '30%', opacity: 1}, 0)

            .to(`.${this.CLASS_DEV_GEAR_TOP}`, 6, {ease: Power1.linear, rotation: '360'}, 1)
            .to(`.${this.CLASS_DEV_GEAR_BOTTOM}`, 6, {ease: Power1.linear, rotation: '-360'}, 1)

            .to(`.${this.CLASS_DEV_IMAGE}`, 1, {ease: Power1.easeOut, x: '0%', opacity: 1}, 1)
            .to(`.${this.CLASS_DEV_TITLE}`, 1, {ease: Power1.easeOut, x: '-40%', y: '40%', opacity: 1}, 2)
            .to(`.${this.CLASS_DEV_CODE}`, 1, {ease: Power1.easeOut, x: '-20%', y: '50%', opacity: 1}, 2)


            .to(`.${this.CLASS_DEV_TITLE}`, 1, {ease: Power1.easeOut, x: '-100%', y: '40%', opacity: 0, delay: 1}, 3)
            .to(`.${this.CLASS_DEV_CODE}`, 1, {ease: Power1.easeOut, x: '100%', y: '50%', opacity: .0, delay: 1}, 3)

            .to(`.${this.CLASS_DEV_IMAGE}`, 1, {ease: Power1.easeOut, x: '50%', opacity: 0}, 5)

            .to(`.${this.CLASS_DEV_GEAR_TOP}`, 1, {ease: Power1.easeOut, x: '-100%', y: '-100%', opacity: 0}, 6)
            .to(`.${this.CLASS_DEV_GEAR_BOTTOM}`, 1, {ease: Power1.easeOut, x: '100%', y: '100%', opacity: 0}, 6)


            .to(`.${this.CLASS_GOV_GEAR_TOP}`, 1, {ease: Power1.easeOut, x: '-100%', y: '-70%', opacity: 1}, 7)
            .to(`.${this.CLASS_GOV_GEAR_BOTTOM}`, 1, {ease: Power1.easeOut, x: '-50%', y: '-50%', opacity: 1}, 7)

            .to(`.${this.CLASS_GOV_GEAR_TOP}`, 5, {ease: Power1.linear, rotation: '360'}, 8)
            .to(`.${this.CLASS_GOV_GEAR_BOTTOM}`, 5, {ease: Power1.linear, rotation: '-360'}, 8)

            .to(`.${this.CLASS_GOV_IMAGE}`, 1, {ease: Power1.easeOut, x: '0%', y: '0%', opacity: 1}, 8)
            .to(`.${this.CLASS_GOV_TITLE}`, 1, {ease: Power1.easeOut, x: '40%', y: '-100%', opacity: 1}, 9)


            .to(`.${this.CLASS_GOV_TITLE}`, 1, {ease: Power1.easeOut, x: '100%', y: '-100%', opacity: 0, delay: 1}, 10)
            .to(`.${this.CLASS_GOV_IMAGE}`, 1, {ease: Power1.easeOut, x: '30%', y: '-30%', opacity: 0}, 11)

            .to(`.${this.CLASS_GOV_GEAR_TOP}`, 1, {ease: Power1.easeOut, x: '-100%', y: '-130%', opacity: 0}, 12)
            .to(`.${this.CLASS_GOV_GEAR_BOTTOM}`, 1, {ease: Power1.easeOut, x: '-50%', y: '50%', opacity: 0}, 12);

        return this;
    }
}
