import axios, {AxiosError, AxiosPromise, AxiosResponse} from 'axios';
import {getCookie} from '../../tools';

export interface Response<T> extends AxiosResponse<T> {}
export interface Reject<T> extends AxiosError<T> {}

export interface RequestFormSuccess {
    message: string;
}

export interface RequestFormError {
    errorFields: RequestFormErrorField;
}

export interface RequestFormErrorField {
    [key: string]: Array<string>;
}

export interface RequestOption {
    url: string;
    method?: string;
    data?: FormData;
}

export default class Request {
    private testServer: boolean = process.env.NODE_ENV === 'development';
    private apacheServer: string = `http://localhost:9000`;
    private testServerURL: string = `http://localhost:9000`;
    // private testServerURL: string = `${window.location.protocol}//${window.location.hostname}:8181`;

    constructor(private option: RequestOption) {
        if (this.testServer) {
            let finalUrl: string = '';
            try {
                const url: URL = new URL(option.url);
                let pathName: string = url.pathname;
                if (pathName.search(/\/php/) !== 1) {
                    pathName = pathName.replace(/\/php/, '');
                }
                finalUrl = this.testServerURL + pathName + url.search;
            } catch (e) {
                finalUrl = this.testServerURL + option.url;
            }
            option.url = finalUrl;
        }
    }

    public send(): AxiosPromise {
        //@ts-ignore
        return axios({
            url: this.option.url,
            method: this.option.method || 'GET',
            data: this.option.data || '',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // 'X-CSRFToken': getCookie('csrftoken'),
                // 'X-Requested-With': 'XMLHttpRequest',
            },
        });
    }
}
