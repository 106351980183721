import CreateEvent from './event/Event';

export enum ThemeTypes {
    dark = 'dark',
    light = 'light',
    none = 'none',
}

export default class Theme {
    public static getInstance() {
        if (!Theme.instance) {
            Theme.instance = new Theme();
            Theme.instance.init();
        }
        return Theme.instance;
    }

    private static instance: Theme;
    private CLASS_DARK: string = 't-dark';
    private CLASS_LIGHT: string = 't-light';

    private STORAGE_KEY: string = 'theme';

    public init(): void {
        const selectTheme: string = localStorage.getItem(this.STORAGE_KEY);
        if (selectTheme) {
            this.toggle(selectTheme as ThemeTypes);
        } else {
            this.toggle(this.systemTheme());
        }
    }

    public toggle(mode: ThemeTypes): void {
        const favicon: HTMLLinkElement = document.querySelector('link[rel*=\'icon\']');

        if (mode === ThemeTypes.dark) {
            favicon.href = favicon.href.replace(/light/, 'dark');
            document.body.classList.remove(this.CLASS_LIGHT);
            document.body.classList.add(this.CLASS_DARK);
        } else if (mode === ThemeTypes.light) {
            favicon.href = favicon.href.replace(/dark/, 'light');
            document.body.classList.remove(this.CLASS_DARK);
            document.body.classList.add(this.CLASS_LIGHT);
        } else {
            favicon.href = favicon.href.replace(/dark/, 'light');
            document.body.classList.remove(this.CLASS_DARK);
            document.body.classList.add(this.CLASS_LIGHT);
        }

        localStorage.setItem(this.STORAGE_KEY, mode);

        dispatchEvent(new CreateEvent('switching-themes').createCustom());
    }

    public theme(): string {
        const isDark: boolean = document.body.classList.contains(this.CLASS_DARK);
        return isDark ? 'dark' : 'light';
    }

    public systemTheme(): ThemeTypes {
        const isDark: boolean = window.matchMedia('(prefers-color-scheme: dark)').matches;
        const isLight: boolean = window.matchMedia('(prefers-color-scheme: light)').matches;
        if (isDark) {
            return ThemeTypes.dark;
        } else if (isLight) {
            return ThemeTypes.light;
        }
        return ThemeTypes.none;
    }

}
