import videojs from 'video.js';

export default class VideoPlayer {
    private CLASS_BLOCK: string = 'js-videoPlayer';

    public init(): void {
        Array.from(document.getElementsByClassName(this.CLASS_BLOCK)).forEach((video) => {
            videojs(video);
        });
    }
}
