// @ts-ignore
import {
    TinySliderSettings,
    tns,
} from '../../../node_modules/tiny-slider/src/tiny-slider';
import {sliderConfigs} from './slider-config';

export default class Slider {
    private sliders: NodeListOf<Element>;
    private slider: HTMLElement;
    private sliderContents: HTMLElement;
    private sliderControl: HTMLElement;
    private sliderDots: HTMLElement;
    private sliderNameConfig: string;

    private CLASS_SLIDER: string = 'js-slider';
    private CLASS_SLIDER_CONTENTS: string = 'js-slider__contents';
    private CLASS_SLIDER_CONTROLS: string = 'js-slider__controls';
    private CLASS_SLIDER_DOTS: string = 'js-slider__dots-container';

    private DATA_ATTR_NAME_CONFIG: string = 'data-slider-name-config';

    public init(): void {
        this.sliders = document.querySelectorAll(`.${this.CLASS_SLIDER}`);

        this.initSliders();
    }

    private initSliders(): void {
        for (let i = 0; i < this.sliders.length; i++) {
            const currentSlider: HTMLElement = this.sliders.item(
                i
            ) as HTMLElement;
            this.slider = currentSlider;
            this.sliderContents = currentSlider.querySelector(
                `.${this.CLASS_SLIDER_CONTENTS}`
            );
            this.sliderControl = currentSlider.querySelector(
                `.${this.CLASS_SLIDER_CONTROLS}`
            );
            this.sliderDots = currentSlider.querySelector(
                `.${this.CLASS_SLIDER_DOTS}`
            );
            this.sliderNameConfig = currentSlider.getAttribute(
                this.DATA_ATTR_NAME_CONFIG
            );

            this.activateSlider();
        }
    }

    private mergeConfig(): TinySliderSettings {
        const config: TinySliderSettings = sliderConfigs[this.sliderNameConfig];
        const defaultConfig: TinySliderSettings = {
            autoplayButtonOutput: false,
            autoplayHoverPause: true,
            container: this.sliderContents,
            controlsContainer: this.sliderControl,
            mouseDrag: true,
            navContainer: this.sliderDots,
        };
        return {...defaultConfig, ...config};
    }

    private activateSlider(): void {
        tns(this.mergeConfig());
    }
}
