// import {TimelineMax} from 'gsap';
// declare var TweenMax: any;
declare var TimelineMax: any;

export default class AnimatedCardForDevelopers {

    private CLASS_CIRCLE: string = 'js-animCardForDevelopersCircle';
    private CLASS_LAPTOP: string = 'js-animCardForDevelopersLaptop';
    private CLASS_IMAGE1: string = 'js-animCardForDevelopersLaptopImage1';
    private CLASS_IMAGE2: string = 'js-animCardForDevelopersLaptopImage2';
    private CLASS_OPENSOURCE: string = 'js-animCardForDevelopersOpensource';
    private CLASS_WORKFLOW: string = 'js-animCardForDevelopersWorkflow';

    private circle: HTMLElement;
    private laptop: HTMLElement;
    private image1: HTMLElement;
    private image2: HTMLElement;
    private opensource: HTMLElement;
    private workflow: HTMLElement;

    private timeline: any;
    private timelineRepeat: any;

    public init(): AnimatedCardForDevelopers {
        this.circle = document.querySelector(`.${this.CLASS_CIRCLE}`);
        this.laptop = document.querySelector(`.${this.CLASS_LAPTOP}`);
        this.image1 = document.querySelector(`.${this.CLASS_IMAGE1}`);
        this.image2 = document.querySelector(`.${this.CLASS_IMAGE2}`);
        this.opensource = document.querySelector(`.${this.CLASS_OPENSOURCE}`);
        this.workflow = document.querySelector(`.${this.CLASS_WORKFLOW}`);
        this.timeline = new TimelineMax();
        this.timelineRepeat = new TimelineMax({
            paused: true,
            repeat: -1,
        });
        return this;
    }

    public animate(): AnimatedCardForDevelopers {
        this.timeline
            .to(this.circle, 0.8, {x: 20, y: -60, opacity: 1})
            .to(this.laptop, 0.8, {x: 0, y: 0, opacity: 1}, '-=1')
            .to(this.opensource, 0.6, {x: 0, opacity: 1, delay: 0.4})
            .to(this.image1, 0.6, {xPercent: -100, delay: 0.4})
            .to(this.image2, 0.6, {x: 0, delay: 0.4}, '-=1')
            .to(this.opensource, 0.6, {xPercent: 100, opacity: 0, delay: 0.4}, '-=1')
            .to(this.workflow, 0.6, {x: 0, opacity: 1, delay: 0.4,
                onComplete: () => {
                    this.timelineRepeat.play();
                },
            }, '-=1');

        this.timelineRepeat
            .to(this.workflow, 0.6, {xPercent: -100, opacity: 0, delay: 1.5})
            .to(this.opensource, 0.6, {xPercent: 0, opacity: 1, delay: 0.4}, '-=1')
            .to(this.image1, 0.6, {xPercent: 0, delay: 0.4}, '-=1')
            .to(this.image2, 0.6, {xPercent: 100, delay: 0.4}, '-=1')

            .to(this.workflow, 0.6, {xPercent: 0, opacity: 1, delay: 1.5})
            .to(this.opensource, 0.6, {xPercent: 100, opacity: 0, delay: 0.4}, '-=1')
            .to(this.image1, 0.6, {xPercent: -100, delay: 0.4}, '-=1')
            .to(this.image2, 0.6, {xPercent: 0, delay: 0.4}, '-=1');

        return this;
    }
}
