import AnimatedFirstSection from './AnimatedFirstSection';
import AnimatedCardForBusiness from '../../components/animated-card-for-business/animatedCardForBusiness';
import AnimatedCardForDevelopers from '../../components/animated-card-for-developers/animatedCardForDevelopers';
import ModulesSection from '../../components/modules-section/modulesSection';
import Cases from '../../components/cases/Cases';

declare var ScrollMagic: any;

export default class AnimatedMain {

    private ID_FOR_BUS_CARD: string = 'js-forBusCard';
    private ID_FOR_DEV_CARD: string = 'js-forDevCard';
    private scrollMagicController: any;

    public init(): void {
        this.scrollMagicController = new ScrollMagic.Controller();

        this.animate();

        if (document.body.scrollIntoView) {
            document.body.scrollIntoView({inline: 'center'});
            document.body.style.overflowX = 'hidden';
        }
    }

    private animate(): void {
        /*Section 1-2*/
        new AnimatedFirstSection().init();
        /*Section 1-2 end*/

        /*Section Cards for business, developers*/
        new ScrollMagic.Scene({
            triggerElement: `#${this.ID_FOR_BUS_CARD}`,
            reverse: false,
        })
            .addTo(this.scrollMagicController)
            // .addIndicators()
            .on('enter leave', () => {
                new AnimatedCardForBusiness().init().animate();
            });
        new ScrollMagic.Scene({
            triggerElement: `#${this.ID_FOR_DEV_CARD}`,
            reverse: false,
        })
            .addTo(this.scrollMagicController)
            // .addIndicators()
            .on('enter leave', () => {
                new AnimatedCardForDevelopers().init().animate();
            });
        /*Section Cards for business, developers end*/

        /*Section modules*/
        new ModulesSection().init().animate();
        /*Section modules end*/

        new Cases().init();

    }
}
