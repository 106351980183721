// @ts-ignore
import {TinySliderInstance, TinySliderSettings, tns} from 'tiny-slider/src/tiny-slider';

export default class Cases {
    private CLASS_SLIDER: string = 'js-casesSlider';
    private CLASS_SLIDER_CONTROLS: string = 'js-casesSliderControls';

    private slider: TinySliderInstance;

    private timeline: any;
    private sliderContainer: HTMLElement;
    private sliderControls: HTMLElement;

    private config: TinySliderSettings = {
        // @ts-ignore
        center: true,
        loop: true,
        nav: false,
        mouseDrag: false,
        speed: 700,
        autoHeight: true,
        // responsive: {
        //     1920: {
        //         fixedWidth: 600,
        //     },
        // },
    };

    public init(): void {
        this.sliderContainer = document.querySelector(`.${this.CLASS_SLIDER}`);
        this.sliderControls = document.querySelector(`.${this.CLASS_SLIDER_CONTROLS}`);
        if (this.sliderContainer && this.sliderControls) {
            this.activateSlider();
        }
    }

    private mergeConfig(): TinySliderSettings {
        const defaultConfig: TinySliderSettings = {
            container: this.sliderContainer,
            controlsContainer: this.sliderControls,
        };
        return {...defaultConfig, ...this.config};
    }

    private activateSlider(): void {
        this.slider = tns(this.mergeConfig());
    }

}
