// import {TimelineMax} from 'gsap';
// declare var TweenMax: any;
declare var TimelineMax: any;

export default class AnimatedCurrency {

    private CLASS_WRAPPER: string = 'js-animatedCurrencyWrapper';
    private CLASS_CONTAINER: string = 'js-animatedCurrencyContainer';
    private CLASS_ITEM_ACTIVE: string = 'is-active';

    private wrapper: HTMLElement;
    private container: HTMLElement;

    private timeline: any;
    private firstActive: boolean;

    constructor(private block: HTMLElement) {
    }

    public init(): AnimatedCurrency {
        this.wrapper = this.block.querySelector(`.${this.CLASS_WRAPPER}`);
        this.container = this.block.querySelector(`.${this.CLASS_CONTAINER}`);
        this.timeline = new TimelineMax({
        });
        return this;
    }

    public animate(): AnimatedCurrency {
        if (!this.firstActive) {
            this.firstActive = true;
        } else {
            this.timeline.play();
            return this;
        }
        const items: Array<Element> = Array.from(this.container.children);
        const index: number = items.findIndex((item) => item.classList.contains(this.CLASS_ITEM_ACTIVE));
        const itemHeight: number = (this.container.children.item(0) as HTMLElement).offsetHeight;
        const height: number = this.container.offsetHeight;
        const y: number = -(itemHeight * (index + 1));
        this.timeline.to(this.wrapper, 0.6, {height})
            .to(this.wrapper, 0.6, {paddingTop: -y, y, paddingBottom: y})
            .to(this.container, 0.4, {y}, 1)
            .to(this.wrapper, 0.4, {height: itemHeight, marginTop: -y, paddingTop: 0}, 1);
        return this;
    }

    public clear(): AnimatedCurrency {
        this.timeline.reverse(1);
        this.block.removeAttribute('style');
        this.wrapper.removeAttribute('style');
        this.container.removeAttribute('style');
        return this;
    }
}
