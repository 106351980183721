// @ts-ignore
// import * as ScrollMagic from 'scrollmagic/scrollmagic/uncompressed/ScrollMagic';
// import {TweenMax, TimelineMax} from 'gsap';

import {WindowBreakpointsMax} from '../../scripts/variables';

declare var ScrollMagic: any;
declare var TimelineMax: any;

export default class AnimatedFirstSection {
    private timeline: any;

    public init(): void {
        window.addEventListener('load', () => {
            setTimeout(() => {
                this.startAnimation();
            }, 500);
        });
        this.timeline = new TimelineMax();
    }

    public startAnimation(): void {
        if (window.innerWidth > WindowBreakpointsMax.md) {
            this.timeline
                .to('#mainSectionTitle', 1, {x: 0, opacity: 1}, 0)
                .to('#mainSectionDescription', 1, {x: 0, opacity: 1, delay: 0.2}, 0)
                .to('#mainFirstSectionWrapper', 0.5, {x: 0, rotation: 18, opacity: 1, delay: 1.5}, 0)
                .to('#mainFirstSectionPhones', 1.5, {x: 0, opacity: 1, delay: 2}, 0);

            const controller = new ScrollMagic.Controller();
            const visibleHeight = window.innerHeight;
            const timeline = new TimelineMax();
            timeline
                .to('#mainFirstSectionPhones', 3, {rotation: 0}, 0)
                .from('#mainFirstSectionWrapper', 3, {rotation: 18}, 0)
                .to('#mainFirstSectionWrapper', 3, {rotation: 0}, 0)
                .to('#mainFirstSection2Wrapper', 3, {rotation: 0}, 0);

            new ScrollMagic.Scene({
                triggerElement: '#mainFirstSectionTrigger',
                duration: visibleHeight,
                offset: visibleHeight / 2,
            })
                .setPin('#mainFirstSectionPhones')
                .setTween(timeline)
                // .addIndicators()
                .addTo(controller);
        }

    }
}
