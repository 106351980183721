// @ts-ignore
import {TinySliderInstance, TinySliderSettings, TinySliderInfo, tns} from 'tiny-slider/src/tiny-slider';
import {WindowBreakpointsMax} from '../../scripts/variables';

declare var ScrollMagic: any;
declare var TimelineMax: any;
declare var Power1: any;

export default class TechnologyStackSlider {
    private CLASS_SECTION: string = 'js-techStackSliderSection';
    private CLASS_SLIDER: string = 'js-technologyStackSlider';
    private CLASS_CONTROLS_DESK: string = 'js-technologyStackSliderBtnsDesk';
    private CLASS_CONTROLS_MOB: string = 'js-technologyStackSliderBtnsMob';
    private CLASS_TITLE: string = 'js-technologyStackSliderTitle';
    private CLASS_DYNAMIC_CONTENT: string = 'js-technologyStackSliderDynamicContent';
    private CLASS_COUNT: string = 'js-techStackSliderCount';
    private CLASS_ACTIVE: string = 'is-active';

    private CLASS_ANIM_SLIDE_TITLE: string = 'js-techStackSliderAnimSlideTitle';
    private CLASS_ANIM_CONTAINER: string = 'js-techStackSliderAnimContainer';
    private CLASS_ANIM_SLIDER_BTN: string = 'js-techStackSliderAnimSlideBtn';

    private DATA_ATTR_TITLE: string = 'data-title';

    private CLASS_HIDE: string = 'u-d-none';

    private sliderContainer: HTMLElement;
    private sliderControls: HTMLElement;
    private title: HTMLElement;
    private slider: TinySliderInstance;
    private dynamicContents: Array<Element>;
    private count: HTMLElement;

    private timeline: any;

    private config: TinySliderSettings = {
        // @ts-ignore
        center: true,
        loop: true,
        nav: false,
        mouseDrag: true,
        speed: 700,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayButton: false,
        responsive: {
            1280: {
                fixedWidth: 600,
            },
            1024: {
                fixedWidth: 480,
            },
            768: {
                fixedWidth: 360,
                // @ts-ignore
                center: false,
            },
        },
    };

    public init(): void {
        this.sliderContainer = document.querySelector(`.${this.CLASS_SLIDER}`);
        if (window.innerWidth > WindowBreakpointsMax.md) {
            this.sliderControls = document.querySelector(`.${this.CLASS_CONTROLS_DESK}`);
        } else {
            this.sliderControls = document.querySelector(`.${this.CLASS_CONTROLS_MOB}`);
        }
        this.title = document.querySelector(`.${this.CLASS_TITLE}`);
        this.dynamicContents = Array.from(document.getElementsByClassName(this.CLASS_DYNAMIC_CONTENT));
        this.count = document.querySelector(`.${this.CLASS_COUNT}`);
        if (!this.sliderContainer) {
            return;
        }
        this.timeline = new TimelineMax();

        this.activateSlider();
        this.animate();
    }

    private animate() {
        const sliderItems: Array<HTMLElement> = Array.from(this.sliderContainer.children) as Array<HTMLElement>;
        const currentIndex: number = this.slider.getInfo().indexCached;
        let startWith: number =  currentIndex - 1;
        if (window.innerWidth < WindowBreakpointsMax.md) {
            startWith = currentIndex - 2;
        }
        this.timeline
            .to(`.${this.CLASS_ANIM_SLIDE_TITLE}`, 1, {ease: Power1.easeOut, y: 0, opacity: 1, delay: 0.5}, 0)
            .to(`.${this.CLASS_ANIM_CONTAINER}`, 1, {ease: Power1.easeOut, y: 0, opacity: 1}, 0);

        sliderItems.forEach((item, index) => {
            const left: ChildNode = item.children[0];
            const right: ChildNode = item.children[1];
            if (index >= startWith) {
                this.timeline
                    .to(left, 0.9, {ease: Power1.easeOut, x: 0, opacity: 1, delay: 0.20}, '-=1')
                    .to(right, 0.9, {ease: Power1.easeOut, x: 0, opacity: 1, delay: 0.20}, '-=1');
            } else {
                this.timeline
                    .to(left, 0, {ease: Power1.easeOut, x: 0, opacity: 1, delay: 0}, 0)
                    .to(right, 0, {ease: Power1.easeOut, x: 0, opacity: 1, delay: 0}, 0);
            }
        });

        this.timeline
            .to(`.${this.CLASS_ANIM_SLIDER_BTN}`, 0.4, {ease: Power1.easeOut, x: 0, opacity: 1, delay: 0.4,
                onComplete: () => {
                    this.slider.play();
                },
            }, 1);

        const controller = new ScrollMagic.Controller();
        new ScrollMagic.Scene({
            triggerElement: `.${this.CLASS_SECTION}`,
            triggerHook: 0.9,
            offset: 200,
            reverse: false,
        })
            .setTween(this.timeline)
            // .addIndicators()
            .addTo(controller);
            // .on('enter leave', ((event: Event) => {
            //     if (event.type === 'enter') {
            //         console.log('asd');
            //     }
            // });
    }

    private mergeConfig(): TinySliderSettings {
        const defaultConfig: TinySliderSettings = {
            container: this.sliderContainer,
            controlsContainer: this.sliderControls,
        };
        return {...defaultConfig, ...this.config};
    }

    private switchDynamicContent(index: number): void {
        this.dynamicContents.forEach((container) => {
            const contents: Array<Element> = Array.from(container.children);
            const prevIndex: number = contents.findIndex((content) => !content.classList.contains(this.CLASS_HIDE));
            const prevItem: Element = container.children.item(prevIndex);
            const item: Element = container.children.item(index);
            if (prevItem) {
                prevItem.classList.add(this.CLASS_HIDE);
            }
            if (item) {
                item.classList.remove(this.CLASS_HIDE);
            }
        });
    }

    private switchDynamicTitle(items: HTMLCollectionOf<Element>, index: number): void {
        this.title.textContent = items.item(index).getAttribute(this.DATA_ATTR_TITLE);
    }

    private attachSliderChange(): void {
        this.slider.events.on('indexChanged', (e) => {
            // @ts-ignore
            this.switchDynamicContent(e.displayIndex - 1);
            // @ts-ignore
            this.switchDynamicTitle(e.slideItems, e.index);
            this.updateCount(this.slider.getInfo());
            this.updateClassInActive(this.slider.getInfo());
        });
    }

    private activateSlider(): void {
        this.slider = tns(this.mergeConfig());
        this.updateCount(this.slider.getInfo());
        this.slider.pause();
        this.attachSliderChange();
        this.updateClassInActive(this.slider.getInfo());
    }

    private updateCount(info: TinySliderInfo): void {
        // @ts-ignore
        let current: string = info.displayIndex.toString();
        let summary: string = info.slideCount.toString();
        if (summary.length === 1) {
            summary = '0' + summary;
        }
        if (current.length === 1) {
            current = '0' + current;
        }
        this.count.textContent = `${current}/${summary}`;
    }

    private updateClassInActive(info: TinySliderInfo): void {
        // @ts-ignore
        const active: number = info.index;
        const lastActive: HTMLElement = info.container.querySelector(`.${this.CLASS_ACTIVE}`);
        if (lastActive) {
            lastActive.classList.remove(this.CLASS_ACTIVE);
        }
        info.slideItems.item(active).classList.add(this.CLASS_ACTIVE);
    }

}
