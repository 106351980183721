import CreateEvent from '../../scripts/modules/event/Event';
import {globalEventDelegate} from '../../scripts/tools';

export default class Select {
    private static openSelections: Array<HTMLElement> = [];

    private CLASS_SELECT: string = 'js-select';
    private CLASS_INPUT: string = 'js-selectInput';
    private CLASS_HEADER: string = 'js-selectHeader';
    private CLASS_HEADER_TITLE: string = 'js-selectHeaderTitle';
    private CLASS_LIST: string = 'js-selectList';
    private CLASS_OPTION: string = 'js-selectOption';
    private CLASS_OPENED: string = 'is-open';
    private CLASS_SELECTED: string = 'is-selected';
    private CLASS_MULTIPLE_LIST: string = 'js-selectMultipleList';
    private CLASS_MULTIPLE_ITEM_PATTERN: string = 'js-selectMultipleItemPattern';
    private CLASS_MULTIPLE_ITEM_DEMO: string = 'c-select-multiple-list__item-demo';
    private CLASS_MULTIPLE_ITEM_REMOVE: string = 'js-selectMultipleItemRemove';
    private CLASS_MULTIPLE_ITEM_LABEL: string = 'js-selectMultipleItemLabel';

    private DATA_ATTR_VALUE: string = 'data-value';
    private DATA_ATTR_TITLE: string = 'data-title';
    private DATA_ATTR_MULTIPLE: string = 'multiple';
    private DATA_ATTR_MULTIPLE_LIST_ID: string = 'multiple-id';

    private select: HTMLElement;
    private header: HTMLElement;
    private list: HTMLElement;
    private title: HTMLElement;
    private input: HTMLInputElement;
    private multiple: boolean;
    private multipleBlock: HTMLElement;
    private multiplePattern: HTMLElement;

    public init(): void {
        this.attachEvent();
    }

    public getValue(select: HTMLElement): string {
        const input: HTMLInputElement = select.querySelector(`.${this.CLASS_INPUT}`);
        return input.value;
    }

    public getName(select: HTMLElement): string {
        const input: HTMLInputElement = select.querySelector(`.${this.CLASS_INPUT}`);
        return input.name;
    }

    public selectByValue(select: HTMLElement, value: string): void {
        this.searchAndSetSelectElements(select);
        const item: HTMLElement = this.searchItemByValue(value);
        if (!item) {
            throw new Error(`Element with the value "${value}" we will not find`);
        }
        this.toggle(item, true);
    }

    public clearAll(scope?: HTMLElement): void {
        let selects: Array<Element> = [];
        if (scope) {
            selects = Array.from(scope.getElementsByClassName(this.CLASS_SELECT));
        } else {
            selects = Array.from(document.getElementsByClassName(this.CLASS_SELECT));
        }
        selects.forEach((select) => {
            this.searchAndSetSelectElements(select as HTMLElement);
            this.clear();
        });
    }

    private attachEvent(): void {
        globalEventDelegate<Event>(
            'click',
            `.${this.CLASS_HEADER}`,
            (header: HTMLElement) => {
                this.searchAndSetSelectElements(header);
                this.toggleDisplay();
            },
            (element: Element) => {
                const item: Element = element.closest(`.${this.CLASS_OPTION}`);
                if (!item) {
                    this.closeAllSelect();
                }
            },
        );

        globalEventDelegate<Event>(
            'click',
            `.${this.CLASS_OPTION}`,
            (option: HTMLElement, event: Event) => {
                event.preventDefault();
                this.searchAndSetSelectElements(option);
                this.toggle(option);
            },
        );

        globalEventDelegate<Event>(
            'click',
            `.${this.CLASS_MULTIPLE_ITEM_REMOVE}`,
            (iconClose: HTMLElement, event: Event) => {
                const multipleBlock: HTMLElement = iconClose.closest(`.${this.CLASS_MULTIPLE_LIST}`);
                const item: HTMLElement = iconClose.closest(`li`);
                const select: HTMLElement = document.querySelector(`[${this.DATA_ATTR_MULTIPLE_LIST_ID}=${multipleBlock.id}]`);
                event.preventDefault();
                this.searchAndSetSelectElements(select);
                this.toggleMultipleItem(item);
            },
        );
    }

    private searchAndSetSelectElements(item: HTMLElement): void {
        this.select = item.closest(`.${this.CLASS_SELECT}`);
        this.header = this.select.querySelector(`.${this.CLASS_HEADER}`);
        this.title = this.header.querySelector(`.${this.CLASS_HEADER_TITLE}`);
        this.list = this.select.querySelector(`.${this.CLASS_LIST}`);
        this.input = this.select.querySelector(`.${this.CLASS_INPUT}`);
        this.multiple = this.select.hasAttribute(this.DATA_ATTR_MULTIPLE);
        if (this.multiple) {
            const multipleBlockId: string = this.select.getAttribute(this.DATA_ATTR_MULTIPLE_LIST_ID);
            if (multipleBlockId) {
                this.multipleBlock = document.getElementById(multipleBlockId);
            }
        }
    }

    private closeAllSelect(): void {
        const lenArr: number = Select.openSelections.length;

        if (lenArr) {
            for (let i = 0; i < lenArr; i++) {
                const select: HTMLElement = Select.openSelections.pop();
                select.classList.remove(this.CLASS_OPENED);
            }
        }
    }

    private toggleDisplay(): void {
        const state: boolean = this.select.classList.contains(
            this.CLASS_OPENED
        );

        if (state) {
            this.select.classList.remove(this.CLASS_OPENED);
            this.closeAllSelect();
        } else {
            this.closeAllSelect();
            this.select.classList.add(this.CLASS_OPENED);
            Select.openSelections.push(this.select);
        }
    }

    private toggle(option: HTMLElement, hidden: boolean = false): void {
        if (this.multiple) {
            this.toggleMultiple(option);
        } else {
            this.toggleField(option);
        }
        if (!hidden) {
            this.toggleDisplay();
        }
        this.select.dispatchEvent(new CreateEvent('change').createDefault());
    }

    private toggleField(option: HTMLElement): void {
        this.select.classList.add(this.CLASS_SELECTED);
        const value: string = option.getAttribute(this.DATA_ATTR_VALUE);
        const title: string = option.getAttribute(this.DATA_ATTR_TITLE);

        const currentOption: HTMLElement = this.list.querySelector(
            `.${this.CLASS_SELECTED}`
        );
        if (currentOption) {
            currentOption.classList.remove(this.CLASS_SELECTED);
        }
        option.classList.add(this.CLASS_SELECTED);
        this.input.value = value;
        this.title.textContent = title;
    }

    private toggleMultiple(option: HTMLElement): void {
        this.select.classList.add(this.CLASS_SELECTED);
        const value: string = option.getAttribute(this.DATA_ATTR_VALUE);
        const title: string = option.getAttribute(this.DATA_ATTR_TITLE);

        if (option.classList.contains(this.CLASS_SELECTED)) {
            option.classList.remove(this.CLASS_SELECTED);
            this.removeMultipleItem(value);
        } else {
            option.classList.add(this.CLASS_SELECTED);
            this.createMultipleItem(title, value);
        }

        const listSelected: Array<string> = this.getListSelected();
        this.title.textContent = listSelected.join(', ');
        if (!listSelected.length) {
            this.select.classList.remove(this.CLASS_SELECTED);
        }
        // this.input.value = value;
        this.updateMultipleInputs();
    }

    private toggleMultipleItem(item: HTMLElement): void {
        const value: string = item.getAttribute(this.DATA_ATTR_VALUE);
        const option: HTMLElement = this.list.querySelector(`[${this.DATA_ATTR_VALUE}=${value}]`);
        option.classList.remove(this.CLASS_SELECTED);
        this.removeMultipleItem(value);
        const listSelected: Array<string> = this.getListSelected();
        this.title.textContent = listSelected.join(', ');
        if (!listSelected.length) {
            this.select.classList.remove(this.CLASS_SELECTED);
        }
        this.updateMultipleInputs();
        this.select.dispatchEvent(new CreateEvent('change').createDefault());
    }

    private createMultipleItem(title: string, value: string): void {
        const pattern: HTMLElement = this.multipleBlock.querySelector(`.${this.CLASS_MULTIPLE_ITEM_PATTERN}`);
        if (pattern) {
            const item: HTMLElement = pattern.cloneNode(true) as HTMLElement;
            item.classList.remove(this.CLASS_MULTIPLE_ITEM_DEMO);
            const label: HTMLElement = item.querySelector(`.${this.CLASS_MULTIPLE_ITEM_LABEL}`);
            if (label) {
                label.textContent = title;
            }
            item.setAttribute(this.DATA_ATTR_VALUE, value);
            this.multipleBlock.append(item);
            this.updateMultipleInputs();
        }
    }

    private updateMultipleInputs(): void {
        const listSelected: Array<string> = this.getListSelected();
        const inputs: NodeListOf<Element> = this.select.querySelectorAll(`.${this.CLASS_INPUT}`);
        const inputPattern: HTMLInputElement = inputs.item(0).cloneNode() as HTMLInputElement;
        inputPattern.type = 'checkbox';
        inputPattern.checked = true;
        if (!inputPattern.name.match(/\[\]/)) {
            inputPattern.name = inputPattern.name + '[]';
        }
        inputs.forEach((value) => {
            value.remove();
        });
        listSelected.forEach((value) => {
            const newInput: HTMLInputElement = inputPattern.cloneNode() as HTMLInputElement;
            newInput.value = value;
            this.select.prepend(newInput);
        });

        if (!listSelected.length) {
            const newInput: HTMLInputElement = inputPattern.cloneNode() as HTMLInputElement;
            newInput.value = '';
            this.select.prepend(newInput);
        }
    }

    private removeMultipleItem(value?: string): void {
        if (value) {
            const item: HTMLElement = this.multipleBlock.querySelector(`[${this.DATA_ATTR_VALUE}=${value}]`);
            item.remove();
        } else {
            this.multipleBlock.textContent = '';
        }
    }

    private getListSelected(): Array<string> {
        const selected: Array<Element> = Array.from(this.list.getElementsByClassName(this.CLASS_SELECTED));
        return selected.map((value) => {
            return value.getAttribute(this.DATA_ATTR_TITLE);
        });
    }

    private clear(): void {
        this.select.classList.remove(this.CLASS_SELECTED);

        const selectedOptions: Array<Element> = Array.from(this.list.getElementsByTagName(this.CLASS_SELECTED));
        selectedOptions.forEach((option) => {
            option.classList.remove(this.CLASS_SELECTED);
        });

        if (this.multiple) {
            const inputs: NodeListOf<Element> = this.select.querySelectorAll(`.${this.CLASS_INPUT}`);
            const inputPattern: HTMLInputElement = inputs.item(0).cloneNode() as HTMLInputElement;
            inputPattern.type = 'checkbox';
            inputPattern.removeAttribute('value');
            inputPattern.checked = false;

            if (!inputPattern.name.match(/\[\]/)) {
                inputPattern.name = inputPattern.name + '[]';
            }
            inputs.forEach((value) => {
                value.remove();
            });
            // const newInput: HTMLInputElement = inputPattern.cloneNode() as HTMLInputElement;
            this.select.prepend(inputPattern);
            this.removeMultipleItem();
        } else {
            this.input.value = '';
        }
        this.title.textContent = '';

        this.select.dispatchEvent(new CreateEvent('change').createDefault());
    }

    private searchItemByValue(value: string): HTMLElement {
        const selector: string = `[${this.DATA_ATTR_VALUE}='${value}']`;
        return this.list.querySelector(selector);
    }
}
