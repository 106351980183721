import {WindowBreakpoints, WindowBreakpointsMax} from '../../scripts/variables';

declare var ScrollMagic: any;
declare var TimelineMax: any;
declare var Power1: any;

export default class AnimatedQualities {
    private CLASS_BLOCK: string = 'js-animatedQualities';
    private CLASS_TITLE_LEFT: string = 'js-animatedQualitiesTitleLeft';
    private CLASS_ITEMS_TOP: string = 'js-animatedQualitiesItemsTop';
    private CLASS_TITLE_RIGHT: string = 'js-animatedQualitiesTitleRight';
    private CLASS_ITEMS_BOTTOM: string = 'js-animatedQualitiesItemsBottom';

    private block: HTMLElement;
    private scrollMagicController: any;

    public init(): void {
        this.block = document.querySelector(`.${this.CLASS_BLOCK}`);

        if (this.block) {
            this.scrollMagicController = new ScrollMagic.Controller();
            this.animate();
        }

    }

    private animate(): void {
        const marginLeftSpace: number = this.block.offsetLeft;
        const spacing: number = this.block.offsetWidth;
        const time: number = 1;
        const speed: number = spacing / time;

        const timelineTop: any = new TimelineMax();
        timelineTop.to(`.${this.CLASS_TITLE_LEFT}`, time, {ease: Power1.easeOut, x: '0%', opacity: 1}, 0);

        const timelineBottom: any = new TimelineMax();
        timelineBottom.to(`.${this.CLASS_TITLE_RIGHT}`, time, {ease: Power1.easeOut, x: '0%', opacity: 1}, 0);

        const containerTop: HTMLElement = document.querySelector(`.${this.CLASS_ITEMS_TOP}`);
        const containerBottom: HTMLElement = document.querySelector(`.${this.CLASS_ITEMS_BOTTOM}`);

        const itemsTop: Array<Element> = Array.from(containerTop.children).reverse();
        const itemsBottom: Array<Element> = Array.from(containerBottom.children);

        if (window.innerWidth >= WindowBreakpoints.lg) {
            itemsTop.forEach((item, index) => {
                const freeCut: number = (item as HTMLElement).offsetLeft - marginLeftSpace;
                const cut: number = spacing - freeCut;
                const cutTime: number = cut / speed;
                timelineTop.to(item, 0.4, {ease: Power1.easeOut, opacity: 1, delay: cutTime}, 0);
            });
            itemsBottom.forEach((item, index) => {
                const lastCut: number = (item as HTMLElement).offsetLeft - marginLeftSpace;
                const cut: number = (item as HTMLElement).offsetWidth + lastCut;
                const cutTime: number = cut / speed;
                timelineBottom.to(item, 0.4, {ease: Power1.easeOut, opacity: 1, delay: cutTime}, 0);
            });
        } else if (window.innerWidth > WindowBreakpointsMax.xs && window.innerWidth < WindowBreakpoints.lg) {
            timelineTop.to(containerTop, 0.4, {ease: Power1.easeOut, opacity: 1, delay: 0.5}, 0);
            timelineBottom.to(containerBottom, 0.4, {ease: Power1.easeOut, opacity: 1, delay: 0.5}, 0);
        } else {
            itemsTop.forEach((item, index) => {
                const timeline: any = new TimelineMax();
                timeline.to(item, time, {ease: Power1.easeOut, y: '0%', opacity: 1}, 0);
                new ScrollMagic.Scene({
                    triggerElement: item,
                    reverse: false,
                    offset: -item.clientHeight,
                })
                    .setTween(timeline)
                    // .addIndicators()
                    .addTo(this.scrollMagicController);
            });
            itemsBottom.forEach((item, index) => {
                const timeline: any = new TimelineMax();
                timeline.to(item, time, {ease: Power1.easeOut, y: '0%', opacity: 1}, 0);
                new ScrollMagic.Scene({
                    triggerElement: item,
                    reverse: false,
                    offset: -item.clientHeight,
                })
                    .setTween(timeline)
                    // .addIndicators()
                    .addTo(this.scrollMagicController);
            });
        }


        new ScrollMagic.Scene({
            triggerElement: `.${this.CLASS_TITLE_LEFT}`,
            reverse: false,
        })
            .setTween(timelineTop)
            // .addIndicators()
            .addTo(this.scrollMagicController);

        new ScrollMagic.Scene({
            triggerElement: `.${this.CLASS_TITLE_RIGHT}`,
            reverse: false,
        })
            .setTween(timelineBottom)
            // .addIndicators()
            .addTo(this.scrollMagicController);
    }
}
