import {globalEventDelegate, getSpecificFirstLevelChildren} from '../../scripts/tools';

export default class Collapse {

    private CLASS_COLLAPSE_SCOPE: string = 'js-collapseScope';
    private CLASS_COLLAPSE: string = 'js-collapse';
    private CLASS_HEADER: string = 'js-collapseHeader';
    private CLASS_COLLAPSE_HEADER_TITLE: string = 'js-collapseHeaderTitle';
    private CLASS_CONTAINER: string = 'js-collapseContent';
    private CLASS_OPENED: string = 'is-open';

    private DATA_ATTR_TITLE_OPEN: string = 'data-title-open';
    private DATA_ATTR_TITLE_CLOSE: string = 'data-title-close';


    public init(): void {
        this.attachEvent();

    }

    private attachEvent(): void {
        globalEventDelegate('click', `.${this.CLASS_HEADER}`, (collapseHeader: HTMLElement) => {
            const collapse: HTMLElement = collapseHeader.parentElement;
            this.toggle(collapse);

            if (this.hasDefineScope(collapse))
                this.closeAllButCurrent(collapse);
        });
    }

    public close(collapse: HTMLElement): void {
        this.headerTitleChange(collapse, true);
        collapse.classList.remove(this.CLASS_OPENED);
    }

    private hasDefineScope(collapse: HTMLElement): boolean {
        return collapse.parentElement.classList.contains(this.CLASS_COLLAPSE_SCOPE);
    }

    private closeAllButCurrent(currentCollapse: HTMLElement): void {
        const scope: HTMLElement = currentCollapse.parentElement;
        const allCollapseInScope: Element[] = getSpecificFirstLevelChildren(scope, this.CLASS_COLLAPSE);

        allCollapseInScope.forEach((collapse: HTMLElement) => {
            if (currentCollapse !== collapse) {
                this.close(collapse);
            }
        })
    }

    private toggle(collapse: HTMLElement): void {
        let container: HTMLElement = collapse.querySelector(`.${this.CLASS_CONTAINER}`);
        let state: boolean = collapse.classList.contains(this.CLASS_OPENED);
        let height: number;
        container.removeAttribute('style');

        let clearStyle = () => {
            container.style.cssText = '';
            container.removeAttribute('style');

            if (state) container.removeAttribute('style');
            container.removeEventListener('transitionend', clearStyle);
        };

        container.style.display = 'block';
        container.style.overflow = 'hidden';
        let paddingTop: number = parseFloat(getComputedStyle(container).paddingTop);
        let paddingBottom: number = parseFloat(getComputedStyle(container).paddingBottom);
        height = container.offsetHeight + paddingTop + paddingBottom;
        this.setStyle(state, collapse, container, height);
        container.addEventListener('transitionend', clearStyle);

    }

    private setStyle(state: boolean, collapse: HTMLElement, container: HTMLElement, height: number): void {
        if (state) {
            container.style.maxHeight = height + 'px';

            setTimeout(() => {
                container.style.maxHeight = '0px';
            }, 50);

            collapse.classList.remove(this.CLASS_OPENED);
            this.headerTitleChange(collapse, true);
        } else {
            collapse.classList.add(this.CLASS_OPENED);
            this.headerTitleChange(collapse, false);
            container.style.maxHeight = '0px';

            setTimeout(() => {
                container.style.maxHeight = height + 'px';
            }, 50);
        }
    }


    private headerTitleChange(collapse: HTMLElement, state: boolean): void {
        let title: HTMLElement = collapse.querySelector(`.${this.CLASS_COLLAPSE_HEADER_TITLE}`);
        let header: HTMLElement = collapse.querySelector(`.${this.CLASS_HEADER}`);
        let open: string = header.getAttribute(this.DATA_ATTR_TITLE_OPEN);
        let close: string = header.getAttribute(this.DATA_ATTR_TITLE_CLOSE);

        if (open || close) {
            state ? title.textContent = open : title.textContent = close;
        }
    }

}
