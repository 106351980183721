import ModalLeaveRequest from './ModalLeaveRequest';
import SendRequest from './SendRequest';
import AnimateSlideDown from './AnimateSlideDown';
import OAuth from './OAuth';

new ModalLeaveRequest().init();
new SendRequest().init();

import './AnimateSlideDown.scss';
new AnimateSlideDown().init();

new OAuth().init();
