import Animation, {AnimationType} from '../../scripts/modules/animation/Animation';

export enum PopupMessageType {
    default = 'c-popup-message_default',
    success = 'c-popup-message_success',
    error = 'c-popup-message_error',
    warning = 'c-popup-message_warning',
    info = 'c-popup-message_info',
    wait = 'c-popup-message_wait',
}

export interface PopupMessageOptions {
    type: PopupMessageType;
    message: string;
}

export default class PopupMessage {

    public static getInstance() {
        if (!PopupMessage.instance) {
            PopupMessage.instance = new PopupMessage();
            PopupMessage.instance.init();
        }
        return PopupMessage.instance;
    }

    private static instance: PopupMessage;

    private CLASS_CONTAINER: string = 'c-popup-message-container';
    private CLASS_ALERT: string = 'c-popup-message';

    private container: HTMLElement;

    constructor() {
        if (PopupMessage.instance) {
            throw new Error('Error: Instantiation failed: Use Alert.getInstance() instead of new.');
        }
        PopupMessage.instance = this;
    }

    public addToast(options: PopupMessageOptions): void {
        const alert: Node = this.container.appendChild(this.createAlert(options));
        this.animate(alert as HTMLElement).toggleVisibility(true);
        this.subscribeToDeactivate(alert as HTMLElement, 2000);
    }

    private init(): void {
        this.container = document.querySelector(`.${this.CLASS_CONTAINER}`);
        if (!this.container) {
            this.createContainer();
        }

        this.showExisting();
    }

    private showExisting(): void {
        const alerts: Array<Element> = Array.from(this.container.children).reverse();

        alerts.forEach((alert: Element, index: number) => {
            setTimeout(() => {
                this.animate(alert as HTMLElement).toggleVisibility(true);
                this.subscribeToDeactivate(alert as HTMLElement, 2000);
            }, 1000 * index);
        });
    }

    private subscribeToDeactivate(alert: HTMLElement, time: number): void {
        setTimeout(() => {
            this.animate(alert).toggleVisibility(false);
        }, time);
    }

    private animate(alert: HTMLElement): Animation {
        return new Animation(alert, {
            classPrefix: 'c-popup-message_animate',
            animationType: AnimationType.animation,
            mutableProperties: ['opacity', 'transform'],
            hooks: {
                leaveCancelled: (element: HTMLElement) => {
                    element.remove();
                },
            },
        });
    }

    private createAlert(options: PopupMessageOptions): HTMLElement {
        // @ts-ignore
        const messageType: PopupMessageType = PopupMessageType[options.type] || PopupMessageType.default;
        const alertStructure: string =
            `
                <div class="c-popup-message ${messageType}" role="message">
                    <h4 class="u-fw-700">${options.message}</h4>
                </div>
            `;
        const fragment: HTMLElement = document.createElement('div');
        fragment.innerHTML = alertStructure;
        return fragment.children[0] as HTMLElement;
    }

    private createContainer(): void {
        const container: HTMLElement = document.createElement('div');
        container.classList.add(this.CLASS_CONTAINER);
        this.container = document.body.appendChild(container);
    }
}
