import {globalEventDelegate} from '../../scripts/tools';

interface IconsExtensions {
    [key: string]: {
        facingUrl: string,
        extensions: Array<string>;
    };
}

export default class InputFile {
    private CLASS_FIELD: string = 'js-inputFile';
    private CLASS_INPUT: string = 'js-inputFileInput';
    private CLASS_LIST: string = 'js-inputFileList';
    private CLASS_SELECTED: string = 'is-selected';

    private field: HTMLElement;
    private input: HTMLInputElement;
    private fieldList: HTMLElement;
    private imgStub: string = '';
    // private imgStub: string = require('../../img/file-icon.svg');
    // private imgStub: string = require('../../img/file-icons/file-icon.svg');

    private readonly iconsExtensions: IconsExtensions = {
        img: {
            facingUrl: 'your',
            extensions: ['apng', 'bmp', 'gif', 'ico', 'cur', 'jpg', 'jpeg',
                'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'tif', 'tiff', 'webp'],
        },
        word: {
            extensions: ['doc', 'dot', 'wbk', 'docx', 'docm', 'dotx', 'dotm', 'docb'],
            facingUrl: '/img/file-icons/word.svg',
        },
        excel: {
            extensions: ['xls', 'xlt', 'xlm', 'xlsx', 'xlsm', 'xltx', 'xltm'],
            facingUrl: '/img/file-icons/excel.svg',
        },
        pdf: {
            extensions: ['pdf'],
            facingUrl: '/img/file-icons/pdf.svg',
        },
        archive: {
            extensions: ['zip', 'tar', '7z', 's7z', 'rar', 'tar.gz', 'tgz', 'tar.Z',
                'tar.bz2', 'tbz2', 'tar.lzma', 'tlz', 'tar.xz', 'txz'],
            facingUrl: '/img/file-icons/winrar.svg',
        },
        cvs: {
            extensions: ['cvs'],
            facingUrl: '/img/file-icons/cvs4.svg',
        },
    };

    public clearAll(scope?: HTMLElement): void {
        let inputs: Array<Element> = [];
        if (scope) {
            inputs = Array.from(scope.getElementsByClassName(this.CLASS_FIELD));
        } else {
            inputs = Array.from(document.getElementsByClassName(this.CLASS_FIELD));
        }
        inputs.forEach((input) => {
            this.searchAndSetTabElements(input as HTMLElement);
            this.clear();
        });
    }

    public init(): void {
        this.attachEvent();
    }

    private attachEvent(): void {
        globalEventDelegate<Event>(
            'change',
            `.${this.CLASS_INPUT}`,
            (input: HTMLInputElement) => {
                this.searchAndSetTabElements(input);
                this.handleFiles(input.files);
            },
        );
    }

    private megabytesToByte(size: number): number {
        const oneByteInMegabytes = 1000000;
        return size * oneByteInMegabytes;
    }

    private searchAndSetTabElements(element: HTMLElement): void {
        this.field = element.closest(`.${this.CLASS_FIELD}`);
        if (!this.field) {
            throw new Error(
                `Element with class ${this.CLASS_FIELD} is not defined!`
            );
        }
        this.input = this.field.querySelector(`.${this.CLASS_INPUT}`);
        this.fieldList = this.field.querySelector(`.${this.CLASS_LIST}`);
    }

    private getFacingUrl(file: File): string {
        const url: string = file.name;
        let facing: string = '/img/file-icons/document.svg';
        const ext: string = url.toString().split('.').pop();
        for (const extension in this.iconsExtensions) {
            if (this.iconsExtensions.hasOwnProperty(extension)) {
                const include: boolean = this.iconsExtensions[extension].extensions.includes(ext.toLocaleLowerCase());
                if (include) {
                    facing = this.iconsExtensions[extension].facingUrl;
                    break;
                }
            }
        }
        if (facing === 'your') {
            facing = URL.createObjectURL(file);
        }
        return facing;
    }

    private handleFiles(files: FileList): void {
        if (!files.length) {
            this.field.classList.remove(this.CLASS_SELECTED);
            this.fieldList.innerHTML = '';
            return;
        }

        let list: string = '';
        Array.from(files).forEach((file) => {
            const facing: string = this.getFacingUrl(file);

            list += `
                <li class="c-input-file__item">
                    <div class="c-input-file__item-pic" style="background-image: url(${facing})"></div>
                    <p class="c-input-file__item-name" title="${file.name}">${file.name}</p>
                </li>
            `;
        });

        this.fieldList.innerHTML = list;

        this.field.classList.add(this.CLASS_SELECTED);
    }

    private clear(): void {
        this.field.classList.remove(this.CLASS_SELECTED);
        this.input.value = '';
        this.fieldList.innerHTML = '';
    }
}
