import {globalEventDelegate} from '../tools';
import Validator from '../modules/form/Validator';
import Request, {Reject, RequestFormError, Response} from '../modules/request/Request';
import PopupMessage, {PopupMessageOptions} from '../../components/popup-message/PopupMessage';

interface SendRequestOptions {
    success: () => void;
}

export default class SendRequest {
    private CLASS_HANDLER: string = 'js-sendRequestHandler';
    private CLASS_FORM: string = 'js-sendRequestForm';
    private CLASS_SCOPE: string = 'js-sendRequestScope';
    private CLASS_LOADING: string = 'is-loading';

    private formValidator: Validator;
    private popupMessage: PopupMessage;

    constructor(handler?: string, private option?: SendRequestOptions) {
        if (handler) {
            this.CLASS_HANDLER = handler;
        }
    }

    public init(): void {
        this.popupMessage = PopupMessage.getInstance();
        this.attachEvent();
        this.formValidator = new Validator();
    }

    private attachEvent(): void {
        globalEventDelegate('click', `.${this.CLASS_HANDLER}`, (btn: HTMLButtonElement, event: Event) => {
            event.preventDefault();
            if (btn.classList.contains(this.CLASS_LOADING)) {
                return;
            }
            const scope: HTMLElement = btn.closest(`.${this.CLASS_SCOPE}`);
            let form: HTMLFormElement;
            if (scope.classList.contains(this.CLASS_FORM)) {
                form = scope as HTMLFormElement;
            } else {
                form = scope.querySelector(`.${this.CLASS_FORM}`);
            }
            this.send(form, btn);
        });
    }

    private send(form: HTMLFormElement, btn: HTMLButtonElement): void {
        btn.disabled = true;
        btn.classList.add(this.CLASS_LOADING);
        new Request({
            url: form.action,
            method: form.method,
            data: new FormData(form),
        }).send()
            .then((response: Response<PopupMessageOptions>) => {
                if (this.option && this.option.success) {
                    this.option.success();
                }
                this.popupMessage.addToast(response.data);
                this.formValidator.clear(form);
                btn.disabled = false;
                btn.classList.remove(this.CLASS_LOADING);
            })
            .catch((reject: Reject<RequestFormError | PopupMessageOptions>) => {
                if (reject.response.status === 302) {
                    this.formValidator.validate(form, reject.response.data as RequestFormError);
                } else {
                    this.popupMessage.addToast(reject.response.data as PopupMessageOptions);
                }
                btn.disabled = false;
                btn.classList.remove(this.CLASS_LOADING);
            });
    }
}
