// import {TimelineMax} from 'gsap';
declare var TimelineMax: any;

export default class AnimatedSelect {

    private CLASS_TITLE: string = 'js-animatedSelectTitle';
    private CLASS_CONTAINER: string = 'js-animatedSelectContainer';
    private CLASS_ITEM_VALUE: string = 'js-animatedSelectItemValue';
    private CLASS_MOUSE: string = 'js-animatedSelectMouse';
    private CLASS_ITEM_ACTIVE: string = 'is-active';

    private title: HTMLElement;
    private container: HTMLElement;
    private mouse: HTMLElement;
    private firstActive: boolean;

    private timeline: any;

    constructor(private block: HTMLElement) {
    }

    public init(): AnimatedSelect {
        this.title = this.block.querySelector(`.${this.CLASS_TITLE}`);
        this.container = this.block.querySelector(`.${this.CLASS_CONTAINER}`);
        this.mouse = this.block.querySelector(`.${this.CLASS_MOUSE}`);
        this.timeline = new TimelineMax();
        return this;
    }

    public animate(): AnimatedSelect {
        if (!this.firstActive) {
            this.firstActive = true;
        } else {
            this.timeline.play();
            return this;
        }
        this.timeline.to(this.container, 0.7, {y: 0, opacity: 1})
            .to(this.mouse, 0.7, {top: 30, x: 20, opacity: 1})
            .to(this.container, 0.3,
                {
                    onUpdate: () => {
                        this.mouse.classList.add('has-click-effect');
                    },
                },
            )
            .to(this.container, 0.7,
                {
                    y: -30, opacity: 0,
                    onStart: () => {
                        const item: HTMLElement = this.container.children.item(0)
                            .querySelector(`.${this.CLASS_ITEM_VALUE}`);
                        this.title.textContent = item.textContent;
                        this.mouse.classList.remove('has-click-effect');
                    },
                },
            );
        return this;
    }

    public clear(): AnimatedSelect {
        this.timeline.reverse(1);
        this.title.textContent = 'English';
        return this;
    }
}
