import Theme, {ThemeTypes} from '../../scripts/modules/theme';
import {globalEventDelegate} from '../../scripts/tools';

export default class ToggleTheme {
    private CLASS_TOGGLE: string = 'js-toggleTheme';
    private CLASS_LIGHT: string = 'is-light';
    private theme: Theme;

    public init(): void {
        this.theme = Theme.getInstance();

        const toggles: Array<Element> = Array.from(document.getElementsByClassName(this.CLASS_TOGGLE));
        toggles.forEach((toggle) => {
            const input: HTMLInputElement = toggle.querySelector('input');
            const isLight: boolean = this.theme.theme() === ThemeTypes.light;
            input.checked = isLight;
            if (isLight) {
                toggle.classList.add(this.CLASS_LIGHT);
            } else {
                toggle.classList.remove(this.CLASS_LIGHT);
            }
        });

        this.attachEvent();
    }

    private attachEvent(): void {
        globalEventDelegate('click', `.${this.CLASS_TOGGLE}`, (toggle, event) => {
            const input: HTMLInputElement = toggle.querySelector('input');
            input.checked = !input.checked;
            if (input.checked) {
                this.theme.toggle(ThemeTypes.light);
                toggle.classList.add(this.CLASS_LIGHT);
            } else {
                toggle.classList.remove(this.CLASS_LIGHT);
                this.theme.toggle(ThemeTypes.dark);
            }
        });
    }
}
