declare var TimelineMax: any;
declare var ScrollMagic: any;
declare var Power1: any;

export default class ModulesSection {
    private CLASS_SECTION: string = 'js-modulesSection';
    private CLASS_GROUP: string = 'js-modulesSectionGroup';

    private timeline: any;

    public init(): ModulesSection {
        this.timeline = new TimelineMax();
        return this;
    }

    public animate(): ModulesSection {
        const groups: Array<HTMLElement> = Array.from(
            document.getElementsByClassName(this.CLASS_GROUP)
        ) as Array<HTMLElement>;
        groups.forEach((group) => {
            const timeline: any = new TimelineMax();
            timeline.to(group, 1, {ease: Power1.easeOut, y: 0, opacity: 1});

            const controller = new ScrollMagic.Controller();
            const scene = new ScrollMagic.Scene({
                triggerElement: group,
                offset: -100,
                reverse: false,
            })
                .setTween(timeline)
                // .addIndicators()
                .addTo(controller);
        });

        return this;
    }
}
