// import {TimelineMax} from 'gsap';
// declare var TweenMax: any;
declare var TimelineMax: any;

import AnimatedCurrency from '../animated-currency/animated-currency';
import AnimatedSelect from '../animated-select/animated-select';

export default class AnimatedCardForBusiness {

    private CLASS_CIRCLE: string = 'js-animCardForBusinessCircle';
    private CLASS_SELECT_LANG: string = 'js-animCardForBusinessSelectLang';
    private CLASS_SELECT_CURRENCY: string = 'js-animCardForBusinessSelectCurrency';
    private CLASS_ITEM_CARD: string = 'js-animCardForBusinessItemCard';
    private CLASS_BUGS: string = 'js-animCardForBusinessBugs';
    private CLASS_SHOPPING: string = 'js-animCardForBusinessItemShopping';
    private CLASS_SHOPPING_TITLE: string = 'js-animCardForShoppingTitle';

    private valid: boolean = true;

    private circle: HTMLElement;
    private selectLang: HTMLElement;
    private selectCurrency: HTMLElement;
    private itemCard: HTMLElement;
    private bugs: HTMLElement;
    private shopping: HTMLElement;
    private shoppingTitle: HTMLElement;

    private selectLangAnimation: AnimatedSelect;
    private selectCurrencyAnimation: AnimatedCurrency;

    private timeline: any;

    public init(): AnimatedCardForBusiness {
        this.circle = document.querySelector(`.${this.CLASS_CIRCLE}`);
        this.selectLang = document.querySelector(`.${this.CLASS_SELECT_LANG}`);
        this.selectCurrency = document.querySelector(`.${this.CLASS_SELECT_CURRENCY}`);
        this.itemCard = document.querySelector(`.${this.CLASS_ITEM_CARD}`);
        this.bugs = document.querySelector(`.${this.CLASS_BUGS}`);
        this.shopping = document.querySelector(`.${this.CLASS_SHOPPING}`);
        this.shoppingTitle = document.querySelector(`.${this.CLASS_SHOPPING_TITLE}`);

        if (this.circle && this.selectLang && this.selectCurrency &&
            this.itemCard && this.bugs && this.shopping && this.shoppingTitle) {
            this.selectLangAnimation = new AnimatedSelect(this.selectLang).init();
            this.selectCurrencyAnimation = new AnimatedCurrency(this.selectCurrency).init();
            this.timeline = new TimelineMax({
                repeat: -1,
            });
        } else {
            this.valid = false;
        }
        return this;
    }

    public animate(): AnimatedCardForBusiness {
        if (!this.valid) {
            return;
        }
        this.timeline
            .to(this.circle, 1, {xPercent: 50, opacity: 1})
            .to(this.itemCard, 1, {x: 0, opacity: 1}, '-=1')
            .to(this.selectLang, 1, {xPercent: 50, opacity: 1})
            .to(this.selectCurrency, 1, {yPercent: 20, opacity: 1,
                onComplete: () => {this.selectCurrencyAnimation.animate(); },
            }, '-=1')
            .to(this.selectCurrency, 1, {delay: 1,
                onComplete: () => {this.selectLangAnimation.animate(); },
            })
            .to(this.itemCard, 1, {xPercent: 50, opacity: 0, delay: 3})
            .to(this.circle, 1, {xPercent: -100, opacity: 0}, '-=1')
            .to(this.selectLang, 1, {xPercent: -100, opacity: 0,
                onComplete: () => {this.selectLangAnimation.clear(); },
            }, '-=1')
            .to(this.selectCurrency, 1, {yPercent: -60, opacity: 0,
                onComplete: () => {this.selectCurrencyAnimation.clear(); },
            }, '-=1')
            .to(this.shopping, 1, {x: 0, opacity: 1})
            .to(this.bugs, 1, {xPercent: -50, opacity: 1})
            .to(this.shoppingTitle, 1, {xPercent: 50, opacity: 1})

            .to(this.bugs, 1, {xPercent: 50, opacity: 0, delay: 1})
            .to(this.shoppingTitle, 1, {xPercent: -50, opacity: 0}, '-=1')
            .to(this.shopping, 1, {xPercent: 50, opacity: 0});

        return this;
    }
}
