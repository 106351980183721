declare var TimelineMax: any;
declare var ScrollMagic: any;
declare var Power1: any;

export default class AnimateSlideDown {
    private CLASS_HANDLER: string = 'js-animateSlideDown';

    public init(): void {
        this.activate();
    }

    private activate(): void {
        const elements: Array<HTMLElement> = Array.from(
            document.getElementsByClassName(this.CLASS_HANDLER),
        ) as Array<HTMLElement>;

        elements.forEach((group) => {
            const timeline: any = new TimelineMax();
            timeline.to(group, 1, {ease: Power1.easeOut, y: 0, opacity: 1});

            const controller = new ScrollMagic.Controller();
            const scene = new ScrollMagic.Scene({
                triggerElement: group,
                offset: -group.clientHeight,
                reverse: false,
            })
                .setTween(timeline)
                // .addIndicators()
                .addTo(controller);
        });
    }
}
