import {globalEventDelegate} from '../tools';

export default class OAuth {
    private CLASS_HANDLER: string = 'js-oauth';

    public init(): void {
        this.attachEvent();
    }

    private attachEvent(): void {
        globalEventDelegate('click', `.${this.CLASS_HANDLER}`, (btn: HTMLElement, event: Event) => {
            event.preventDefault();
        });
    }
}
