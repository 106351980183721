import Animation, {
    AnimationType,
} from '../../scripts/modules/animation/Animation';
import CreateEvent from '../../scripts/modules/event/Event';
import {
    debounce,
    getIndexElement,
    globalEventDelegate,
} from '../../scripts/tools';
import SmoothScroll from '../../scripts/modules/scroll/SmoothScroll';
import {WindowBreakpointsMax} from '../../scripts/variables';
import Theme, {ThemeTypes} from '../../scripts/modules/theme';

export default class Tabs {
    private CLASS_TABS: string = 'js-tabs';
    private CLASS_INPUT: string = 'js-tabsInput';
    private CLASS_HEADER: string = 'js-tabsHeader';
    private CLASS_UNDERLINE: string = 'js-tabsHeaderUnderline';
    private CLASS_NAV: string = 'js-tabsNav';
    private CLASS_TAB: string = 'js-tabsTab';
    private CLASS_ACTIVE: string = 'is-active';
    private CLASS_ACTIVE_START: string = 'is-active-start';
    private CLASS_CONTENT: string = 'js-tabsContent';
    private CLASS_ITEM: string = 'js-tabsItem';
    private CLASS_BURGER_BTN: string = 'js-tabsBurgerBtn';
    private CLASS_OPEN: string = 'is-open';

    private DATA_ATTR_VALUE: string = 'data-value';
    private DATA_ACTIVE_GLOBAL_CLASS: string = 'data-active-global-class';
    private DATA_STATE: string = 'data-state';
    private DATA_EXTENSION: string = 'data-extension';
    private DATA_COLOR: string = 'data-color';
    private DATA_COLOR_DARK: string = 'data-color-dark';

    private tabs: HTMLElement;
    private input: HTMLInputElement;
    private underline: HTMLElement;
    private nav: HTMLElement;
    private header: HTMLElement;
    private contents: HTMLElement;
    private burgerBtn: HTMLButtonElement;
    private animationUnderline: Animation = new Animation(undefined, {
        animationType: AnimationType.transition,
        mutableProperties: ['width', 'transform'],
    });

    private theme: Theme;

    public init() {
        this.theme = Theme.getInstance();
        this.attachEvent();
        this.detectActiveTab();
        this.update();
    }

    public switchByIndex(tabs: HTMLElement, index: number): void {
        const tab: Element = tabs.querySelector(`.${this.CLASS_NAV}`).children[index];
        if (tab) {
            this.switch(tab as HTMLElement);
        } else {
            throw new Error('Tab is not defined');
        }
    }

    private attachEvent(): void {
        globalEventDelegate(
            'click',
            `.${this.CLASS_TAB}`,
            (tab: HTMLElement) => {
                if (tab.classList.contains(this.CLASS_ACTIVE)) {
                    return;
                }
                this.switch(tab);
            },
            (element, event) => {
                if (element.classList.contains(this.CLASS_BURGER_BTN)) {
                    this.switchShortMenu(element as HTMLButtonElement);
                }
                if (element.closest(`.${this.CLASS_CONTENT}`)) {
                    if (this.inOpenShortMenu(element)) {
                        this.switchShortMenu(element);
                    }
                }
            },
        );
        window.addEventListener(
            'resize',
            debounce(this, this.updateShiftUnderline, 60)
        );
    }

    private switchShortMenu(element: HTMLElement): void {
        this.searchAndSetTabElements(element);
        const handler: EventListener = () => {
            if (this.header.getAttribute(this.DATA_STATE) === 'close') {
                this.burgerBtn.removeAttribute('style');
                this.header.classList.remove(this.CLASS_OPEN);
            }
        };
        if (this.header.classList.contains(this.CLASS_OPEN)) {
            this.header.setAttribute(this.DATA_STATE, 'close');
            this.header.style.minWidth = '1px';
        } else {
            this.header.setAttribute(this.DATA_STATE, 'open');
            this.burgerBtn.style.left = getComputedStyle(this.burgerBtn).left;
            // this.burgerBtn.style.left = getComputedStyle(this.burgerBtn).left;
            if (window.innerWidth > WindowBreakpointsMax.xs) {
                this.header.style.minWidth = '330px';
            } else {
                this.header.style.minWidth = '270px';
            }
            this.header.classList.add(this.CLASS_OPEN);
            this.header.addEventListener('transitionend', handler);
        }
    }

    private inOpenShortMenu(element: HTMLElement): boolean {
        this.tabs = element.closest(`.${this.CLASS_TABS}`);
        this.header = this.tabs.querySelector(`.${this.CLASS_HEADER}`);
        return this.header.classList.contains(this.CLASS_OPEN);
    }

    private searchAndSetTabElements(tab: HTMLElement): void {
        this.tabs = tab.closest(`.${this.CLASS_TABS}`);
        this.underline = this.tabs.querySelector(`.${this.CLASS_UNDERLINE}`);
        this.header = this.tabs.querySelector(`.${this.CLASS_HEADER}`);
        this.nav = this.tabs.querySelector(`.${this.CLASS_NAV}`);
        this.contents = this.tabs.querySelector(`.${this.CLASS_CONTENT}`);
        this.input = this.tabs.querySelector(`.${this.CLASS_INPUT}`);
        this.burgerBtn = this.tabs.querySelector(`.${this.CLASS_BURGER_BTN}`);
        this.animationUnderline.updateElement(this.underline);
    }

    private setValueOnInput(tab: HTMLElement): void {
        const value: string = tab.getAttribute(this.DATA_ATTR_VALUE);
        if (this.input) {
            this.input.value = value;
        }
    }

    private setGlobalClassWhenActive(
        tab: HTMLElement,
        lastTab: HTMLElement
    ): void {
        const globalClass: string = tab.getAttribute(
            this.DATA_ACTIVE_GLOBAL_CLASS
        );
        const globalClassLast: string = lastTab.getAttribute(
            this.DATA_ACTIVE_GLOBAL_CLASS
        );

        if (this.tabs) {
            if (globalClassLast) {
                this.tabs.classList.remove(...globalClassLast.split(' '));
            }
            if (globalClass) {
                this.tabs.classList.add(...globalClass.split(' '));
            }
        }
    }

    private shiftUnderline(indexTab: number): void {
        const tab: HTMLElement = this.nav.children.item(
            indexTab
        ) as HTMLElement;
        // this.underline.style.width = `${tab.offsetWidth}px`;
        if (this.tabs.getAttribute(this.DATA_EXTENSION) === 'secondary') {
            let color: string = tab.getAttribute(this.DATA_COLOR);
            if (this.theme.theme() === ThemeTypes.dark) {
                color = tab.getAttribute(this.DATA_COLOR_DARK);
            }
            this.underline.style.borderColor = color;
            this.underline.style.transform = `translateX(${tab.offsetLeft}px)`;
        } else {
            this.underline.style.transform = `translateY(${tab.offsetTop + (tab.offsetHeight / 4) }px)`;
        }
    }

    private switch(tab: HTMLElement): void {
        this.searchAndSetTabElements(tab);
        const event: Event = new CreateEvent('change').createDefault();
        const canceled: boolean = !this.tabs.dispatchEvent(event);
        if (canceled) {
            return;
        }

        const indexTab: number = getIndexElement(this.nav, tab);
        const lastActiveTab: HTMLElement = this.nav.querySelector(
            `.${this.CLASS_TAB}.${this.CLASS_ACTIVE}`
        );
        const lastActiveContent: HTMLElement = this.contents.querySelector(
            `.${this.CLASS_ITEM}.${this.CLASS_ACTIVE}`
        );

        this.isFirstInteraction(lastActiveTab, lastActiveTab);
        lastActiveTab.classList.remove(this.CLASS_ACTIVE);
        lastActiveContent.classList.remove(this.CLASS_ACTIVE);
        this.animationUnderline.execute({
            action: () => {
                this.shiftUnderline(indexTab);
            },
        });
        this.nav.children.item(indexTab).classList.add(this.CLASS_ACTIVE);
        this.contents.children.item(indexTab).classList.add(this.CLASS_ACTIVE);
        this.setValueOnInput(tab);
        this.setGlobalClassWhenActive(tab, lastActiveTab);

        new SmoothScroll('', {
            block: 'nearest',
            inline: 'center',
        }).scrollTo(tab);
    }

    private isFirstInteraction(activeTab: HTMLElement, lastActiveTab: HTMLElement): void {
        if (activeTab.classList.contains(this.CLASS_ACTIVE_START)) {
            this.underline.style.transition = '0s';
            this.underline.style.transform = `translateX(${lastActiveTab.offsetLeft}px)`;
            // this.underline.style.width = `${lastActiveTab.offsetWidth}px`;
            this.underline.style.transition = '';
            activeTab.classList.remove(this.CLASS_ACTIVE_START);
        }
    }

    private detectActiveTab(): void {
        const anchor: string = location.hash.substr(1);
        if (anchor.indexOf('tab') !== -1) {
            const tabs: NodeListOf<Element> = document.querySelectorAll(
                `.${this.CLASS_TAB}[href="#${anchor}"]`
            );
            Array.from(tabs).forEach((tab: HTMLElement) => {
                this.switch(tab);
            });
        }
    }

    private update(): void {
        const tabsList: NodeListOf<Element> = document.querySelectorAll(`.${this.CLASS_TABS}`);
        Array.from(tabsList).forEach((tabs: HTMLElement) => {
            this.searchAndSetTabElements(tabs);
            const activeTab: HTMLElement = this.nav.querySelector(`.${this.CLASS_ACTIVE}`);
            const indexTab: number = getIndexElement(this.nav, activeTab);
            this.shiftUnderline(indexTab);
        });
    }

    private updateShiftUnderline(): void {
        const tabs: Array<Element> = Array.from(
            document.getElementsByClassName(this.CLASS_TABS)
        );
        tabs.forEach((tab: Element) => {
            const activeTab: HTMLInputElement = tab.querySelector(
                `.${this.CLASS_NAV} .${this.CLASS_ACTIVE}`
            );
            this.searchAndSetTabElements(tab as HTMLElement);
            const indexTab: number = getIndexElement(this.nav, activeTab);
            this.shiftUnderline(indexTab);
        });
    }
}
