import AnimatedCardInterests from '../../components/animated-card-interests/animatedCardInterests';

declare var ScrollMagic: any;
declare var TimelineMax: any;
declare var Power1: any;

export default class AnimatedCareer {
    private CLASS_INTERESTS: string = 'js-careerInterests';
    private CLASS_VIDEO_UNDERCOVER: string = 'js-careerVideoUndercover';
    private CLASS_VIDEO_BOX: string = 'js-careerVideoBox';
    private CLASS_FIRST_SECTION: string = 'js-careerFirstSection';
    private scrollMagicController: any;

    public init(): void {
        this.scrollMagicController = new ScrollMagic.Controller();

        this.animate();
    }

    private animate(): void {
        // First section
        const timelineFirstSection: any = new TimelineMax();
        timelineFirstSection
            .to(`.${this.CLASS_FIRST_SECTION}`, 1, {ease: Power1.easeOut, x: `0%`, opacity: 1});
        new ScrollMagic.Scene({
            triggerElement: `.${this.CLASS_VIDEO_UNDERCOVER}`,
            reverse: false,
        })
            .setTween(timelineFirstSection)
            // .addIndicators()
            .addTo(this.scrollMagicController);
        // First section end


        new ScrollMagic.Scene({
            triggerElement: `.${this.CLASS_INTERESTS}`,
            reverse: false,
        })
            .addTo(this.scrollMagicController)
            // .addIndicators()
            .on('enter leave', () => {
                new AnimatedCardInterests().init().animate();
            });
    }
}
