import {globalEventDelegate} from '../../scripts/tools';
import SmoothScroll from '../../scripts/modules/scroll/SmoothScroll';
import Select from '../../components/select/Select';

export default class ApplyForAdmission {
    private CLASS_FORM: string = 'js-applyForAdmissionForm';
    private CLASS_SELECT_VACANCY: string = 'js-applyForAdmissionSelectVacancy';
    private CLASS_BTN_CHOOSE_TOP_VACANCY: string = 'js-applyForAdmissionBtnChooseTopVacancy';

    private DATA_ATTR_TOP_VACANCY_VALUE: string = 'data-top-vacancy-value';

    private form: HTMLElement;
    private selectVacancy: HTMLElement;

    private select: Select;

    public init(): void {
        this.form = document.querySelector(`.${this.CLASS_FORM}`);

        if (!this.form) {
            return;
        }
        this.select = new Select();
        this.selectVacancy = document.querySelector(`.${this.CLASS_SELECT_VACANCY}`);
        this.attachEvent();
    }

    private attachEvent(): void {
        globalEventDelegate('click', `.${this.CLASS_BTN_CHOOSE_TOP_VACANCY}`, (btn, event) => {
            event.preventDefault();
            const vacancy: string = btn.getAttribute(this.DATA_ATTR_TOP_VACANCY_VALUE);

            if (vacancy) {
                this.select.selectByValue(this.selectVacancy, vacancy);
                new SmoothScroll('', {
                    block: 'nearest',
                }).scrollTo(this.form);
            }
        });
    }
}
