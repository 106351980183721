import {globalEventDelegate} from '../../scripts/tools';

// import {TimelineMax} from 'gsap';
// declare var TweenMax: any;
declare var TimelineMax: any;
declare var Power2: any;
declare var Power4: any;

export default class MainMenu {
    private CLASS_BTN: string = 'js-mainMenuBtn';
    private CLASS_BLOCK: string = 'js-mainMenu';
    private CLASS_INNER: string = 'js-mainMenuInner';
    private CLASS_INNER_TOP: string = 'js-mainMenuInnerTop';
    private CLASS_INNER_CENTER: string = 'js-mainMenuInnerCenter';
    private CLASS_INNER_BOTTOM: string = 'js-mainMenuInnerBottom';
    private CLASS_NAV: string = 'js-mainMenuNav';

    private CLASS_HEADER_FIXED: string = 'c-header_fixed';
    private CLASS_LOGO_MENU_STYLE: string = 'c-logo_menu';
    private CLASS_BTN_LIGHT: string = 'c-burger-menu_light';

    private CLASS_HEADER: string = 'js-header';
    private CLASS_HEADER_LOGO: string = 'js-logo';

    private CLASS_BTN_OPEN: string = 'c-burger-menu_open';

    private DATA_ATTR_STATE: string = 'data-state';
    private DATA_ATTR_INTACT: string = 'data-intact';

    private header: HTMLElement;
    private headerLogo: HTMLElement;
    private btn: HTMLButtonElement;
    private block: HTMLElement;
    private inner: HTMLElement;
    private innerTop: HTMLElement;
    private innerCenter: HTMLElement;
    private innerBottom: HTMLElement;
    private nav: HTMLElement;
    private navList: HTMLElement;

    private timeLineOpen: any;
    private timeLineClose: any;

    public init(): void {
        this.block = document.querySelector(`.${this.CLASS_BLOCK}`);
        this.btn = document.querySelector(`.${this.CLASS_BTN}`);
        this.header = document.querySelector(`.${this.CLASS_HEADER}`);
        this.headerLogo = document.querySelector(`.${this.CLASS_HEADER_LOGO}`);

        if (this.block) {
            this.inner = this.block.querySelector(`.${this.CLASS_INNER}`);
            this.innerTop = this.block.querySelector(`.${this.CLASS_INNER_TOP}`);
            this.innerCenter = this.block.querySelector(`.${this.CLASS_INNER_CENTER}`);
            this.innerBottom = this.block.querySelector(`.${this.CLASS_INNER_BOTTOM}`);
            this.nav = this.block.querySelector(`.${this.CLASS_NAV}`);

            this.attachEvent();

            this.timeLineOpen = new TimelineMax({paused: true});
            this.timeLineOpen
                .add('preOpen')
                .to(this.inner, 0, {
                    x: 0,
                    onComplete: () => {
                        this.header.classList.add(this.CLASS_HEADER_FIXED);
                        this.block.style.visibility = 'visible';
                        this.btn.classList.add(this.CLASS_BTN_LIGHT);
                        this.headerLogo.classList.add(this.CLASS_LOGO_MENU_STYLE);
                    },
                }, 'preOpen')
                .add('open')
                .to(this.innerTop, 0.8, {
                    y: '1%',
                    ease: Power4.easeInOut,
                }, 'open')
                .to(this.innerCenter, 0.8, {
                    scaleY: 1,
                    ease: Power4.easeInOut,
                }, 'open')
                .to(this.innerBottom, 0.8, {
                    y: '-1%',
                    ease: Power4.easeInOut,
                    onComplete: () => {
                        this.btn.disabled = false;

                        this.innerTop.style.cssText = 'transform: translate(-50%, 1%) rotate(-45deg)';
                        this.innerCenter.style.cssText = 'transform: translate(-50%, -50%) rotate(-45deg) scaleY(1)';
                        this.innerBottom.style.cssText = 'transform: translate(-50%, -1%) rotate(-45deg)';
                    },
                }, 'open')
                .fromTo(this.nav, 0.6, {
                    y: 30, opacity: 0, visibility: 'hidden',
                }, {
                    y: 0, opacity: 1, visibility: 'visible', ease: Power4.easeOut,
                }, '-=0.2');

            this.timeLineClose = new TimelineMax({paused: true});
            this.timeLineClose
                .add('close')
                .to(this.nav, 0.6, {
                    y: 20, opacity: 0, ease: Power4.easeOut,
                    onComplete: () => {
                        this.nav.style.visibility = 'hidden';

                        this.btn.classList.remove(this.CLASS_BTN_LIGHT);
                        this.headerLogo.classList.remove(this.CLASS_LOGO_MENU_STYLE);
                        this.header.classList.remove(this.CLASS_HEADER_FIXED);
                    },
                }, 'close')
                .to(this.innerTop, 0.8, {
                    y: '-130%',
                    ease: Power4.easeInOut,
                }, 'close', '+=0.2')
                .to(this.innerCenter, 0.8, {
                    scaleY: 0,
                    ease: Power4.easeInOut,
                }, 'close', '+=0.2')
                .to(this.innerBottom, 0.8, {
                    y: '130%',
                    ease: Power4.easeInOut,
                    onComplete: () => {
                        this.block.style.visibility = 'hidden';
                        this.btn.disabled = false;

                        this.innerTop.style.cssText = 'transform: translate(-50%, -130%) rotate(-45deg)';
                        this.innerCenter.style.cssText = 'transform: translate(-50%, -50%) rotate(-45deg) scaleY(0)';
                        this.innerBottom.style.cssText = 'transform: translate(-50%, 130%) rotate(-45deg)';
                    },
                }, 'close', '+=0.2');
        }
    }

    private attachEvent(): void {
        globalEventDelegate('click', `.${this.CLASS_BTN}`, (btn, event) => {
            event.preventDefault();
            const state: string = this.block.getAttribute(this.DATA_ATTR_STATE);
            if (this.btn.disabled) {
                return;
            }
            this.btn.disabled = true;

            if (state === 'close') {
                btn.classList.add(this.CLASS_BTN_OPEN);
                this.block.setAttribute(this.DATA_ATTR_STATE, 'open');
                if (this.timeLineOpen.progress() < 1) {
                    this.timeLineOpen.play();
                } else {
                    this.timeLineOpen.restart();
                }
            } else {
                btn.classList.remove(this.CLASS_BTN_OPEN);
                this.block.setAttribute(this.DATA_ATTR_STATE, 'close');
                if (this.timeLineClose.progress() < 1) {
                    this.timeLineClose.play();
                } else {
                    this.timeLineClose.restart();
                }
            }
        });
    }
}
